import React from "react"
import styled from "styled-components"
import { tablet } from "../util/mediaQueries"

interface Props {
  grey?: boolean
  children: React.ReactNode
}

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: ${props => (props.grey ? props.theme.second : "white")};
  width: 100%;
`

const PageSection = (props: Props) => (
  <RootDiv grey={props.grey}>{props.children}</RootDiv>
)

export default PageSection

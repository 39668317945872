import React, { useContext } from "react"
import styled from "styled-components"
import DownloadIcon from "../icons/downloadIcon"
import { BackendContext } from "../services"
import { openPdfBlob } from "../util/pdfUtil"
import Text, { TextType } from "./text"
import { FiDownload } from "react-icons/fi"

const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: -10px;
  width: 100%;
`
const StyledLi = styled.li`
  width: 100%;
  padding: 0;
  margin: 0;
`

const ListItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.second};
  border-radius: 10px;
  box-shadow: ${props => props.theme.shadow};
  &:hover {
    box-shadow: ${props => props.theme.shadowHovered};
  }
  transition: box-shadow 0.2s;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  min-width: 100%;
  cursor: pointer;
`

const TextDiv = styled.div`
  margin-left: 20px;
`

const IconDiv = styled.div`
  min-width: 30px;
`

const ListItem = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: () => void
}) => (
  <ListItemDiv onClick={onClick}>
    <IconDiv>
      <FiDownload size="30px" />
    </IconDiv>
    <TextDiv onClick={onClick}>
      <Text type={TextType.Body}>{children}</Text>
    </TextDiv>
  </ListItemDiv>
)

interface Props {
  files: string[]
}

const DownloadList = (props: Props) => {
  const { downloadDocument } = useContext(BackendContext)

  const onItemClick = fileName => () => {
    downloadDocument(fileName).then(blob => openPdfBlob(blob, fileName))
  }
  return (
    <StyledList>
      {props.files.map((fileName, index) => (
        <StyledLi key={index}>
          <ListItem onClick={onItemClick(fileName)}>{fileName}</ListItem>
        </StyledLi>
      ))}
    </StyledList>
  )
}

export default DownloadList

export const openPdfBlob = (blob, fileName) => {
    if (blob !== null) {

        const doc = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(doc);
            return;
        }


        const docURL = URL.createObjectURL(doc)

        var link = document.createElement('a');
        link.href = docURL;
        link.download = fileName;
        link.click();

        setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(docURL);
        }, 100);

    }
}

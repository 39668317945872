import { StaticImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import styled from "styled-components"
import ContentDiv from "../components/contentDiv"
import DownloadList from "../components/downloadList"
import FirstSection from "../components/firstSection"
import Layout from "../components/layout"
import PageImage from "../components/pageImage"
import PageSection from "../components/pageSection"
import { BackendContext } from "../services"
import { tablet, laptop } from "../util/mediaQueries"
import { Media } from "gatsby-plugin-fresnel"
import SEO from "../components/seo"
import { FiDownload } from "react-icons/fi"

const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
`

const DownloadDiv = styled.div`
  width: 100%;
  max-width: 100%;
`

const Downloads = () => {
  const { documents } = useContext(BackendContext)

  return (
    <Layout>
      <SEO lang="de" title="Downloads" />
      <PageImage>
        <Media greaterThanOrEqual="md">
          <StaticImage
            src="../images/Titelbild_Downloads_PC.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
        <Media at="sm">
          <StaticImage
            src="../images/Titelbild_Downloads_PC.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
      </PageImage>

      <PageSection>
        <ContentDiv>
          <RootDiv>
            <DownloadDiv>
              <DownloadList files={documents} />
            </DownloadDiv>
          </RootDiv>
        </ContentDiv>
      </PageSection>
    </Layout>
  )
}

export default Downloads
